import React from 'react';

export const Instagram: React.FC = () => (
<svg xmlns="http://www.w3.org/2000/svg"  version="1.1" width="32" height="32" viewBox="0 0 290 290">
<g id="icon" style={{
    stroke: "none",     
    strokeWidth: 1, 
    strokeDasharray: "none", 
    strokeLinecap: "butt", 
    strokeLinejoin: "miter", 
    strokeMiterlimit: 10, 
    fill: "none", 
    fillRule: "nonzero", 
    opacity: 1}} 
    transform="translate(-1.6111111111111143 -1.6111111111111143) scale(3.22 3.22)" >
	<path d="M 62.263 90 H 27.738 C 12.443 90 0 77.557 0 62.263 V 27.738 C 0 12.443 12.443 0 27.738 0 h 34.525 C 77.557 0 90 12.443 90 27.738 v 34.525 C 90 77.557 77.557 90 62.263 90 z M 27.738 7 C 16.303 7 7 16.303 7 27.738 v 34.525 C 7 73.697 16.303 83 27.738 83 h 34.525 C 73.697 83 83 73.697 83 62.263 V 27.738 C 83 16.303 73.697 7 62.263 7 H 27.738 z" 
        style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(255,255,255)",
            fillRule: "nonzero",
            opacity: 1}}
            transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
	<path d="M 45.255 70.47 c -13.904 0 -25.215 -11.312 -25.215 -25.215 S 31.352 20.04 45.255 20.04 s 25.215 11.312 25.215 25.215 S 59.159 70.47 45.255 70.47 z M 45.255 27.04 c -10.044 0 -18.215 8.171 -18.215 18.215 c 0 10.044 8.171 18.215 18.215 18.215 c 10.044 0 18.215 -8.171 18.215 -18.215 C 63.471 35.211 55.3 27.04 45.255 27.04 z" 
    style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter", 
        strokeMiterlimit: 10, 
        fill: "rgb(255,255,255)", 
        fillRule: "nonzero", 
        opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
	<circle cx="70.557" cy="19.936999999999998" r="4.897" 
        style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(255,255,255)",
            fillRule: "nonzero", 
            opacity: 1}} 
            transform="  matrix(1 0 0 1 0 0) "/>
</g>
</svg>
);
